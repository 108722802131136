(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("Velocity"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "Velocity"], factory);
	else if(typeof exports === 'object')
		exports["velocityReact"] = factory(require("React"), require("ReactDOM"), require("Velocity"));
	else
		root["velocityReact"] = factory(root["React"], root["ReactDOM"], root["Velocity"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__43__, __WEBPACK_EXTERNAL_MODULE__170__) {
return 